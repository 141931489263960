"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = isMD5;
var _assertString = _interopRequireDefault(require("./util/assertString"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
var md5 = /^[a-f0-9]{32}$/;
function isMD5(str) {
  (0, _assertString.default)(str);
  return md5.test(str);
}
module.exports = exports.default;
module.exports.default = exports.default;