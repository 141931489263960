import { Box } from "@mui/material"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

import { ProductType } from "../../../../data/generated-sources/openapi"
import { checkZevAppEnabled } from "../../../../services/utils/ContractUtils"
import { getConfigFile } from "../../../../services/utils/SharedUtils"
import { PrimaryTab, PrimaryTabContainer } from "../../../../uikit/tabs/PrimaryTab"
import { useAppSelector } from "../../../hooks"
import ProductHistory from "../../shared/productHistory/ProductHistory"
import ContractSelector from "../contractSelector/ContractSelector"
import { selectSelectedContractData } from "../store/contractSelectorSlice"
import { selectManagerSettingActiveTab } from "../store/uiSlice"
import TabPanel from "../utilityUnit/UtilityUnitDetail"
import { BillingSettingsTab } from "./components/billingPreferences/BillingSettingsTab"
import { SettingsElectricityTab } from "./components/electricity/SettingsElectricityTab"
import FeesWrapper from "./components/fees/FeesWrapper"
import { ZevAppParticipants } from "./components/participants/ZevAppParticipants"
import { SettingsVewaCostSharesTab } from "./components/vewaCostShares/SettingsVewaCostSharesTab"
import ZevAppActivation from "./components/zevAppActivation/ZevAppActivation"
import { ManagerSettingsTabsModels } from "./ManagerSettingsModels"

export const ManagerSettings = () => {
  const { t } = useTranslation("settings")
  const { contractId, contractProductType } = useAppSelector(selectSelectedContractData)
  const isZev = contractProductType === ProductType.ZEV
  const [selectedTab, setSelectedTab] = useState(0)
  const activeTab = useAppSelector(selectManagerSettingActiveTab)
  const [isFeatureZEVAppEnabled, setIsFeatureZEVAppEnabled] = useState(false)
  const isDev = process.env.NODE_ENV === "development"

  useEffect(() => {
    setSelectedTab(activeTab)
    getConfigFile().then((response) =>
      setIsFeatureZEVAppEnabled(isDev ? true : checkZevAppEnabled(response?.FEATURE_ZEV_APP, contractId)),
    )
  }, [activeTab])

  useEffect(() => {
    setSelectedTab(0)
  }, [contractId])

  const zevTabs: ManagerSettingsTabsModels[] = [
    {
      name: "billings",
      label: t("tab.billings"),
    },
    {
      name: "fee",
      label: t("tab.fees"),
    },
    {
      name: "electricity",
      label: t("tab.electricity"),
    },
    {
      name: "product-history",
      label: t("tab.productHistory"),
    },
  ]

  const vewaTabs: ManagerSettingsTabsModels[] = [
    {
      name: "billings",
      label: t("tab.billings"),
    },
    {
      name: "fee",
      label: t("tab.fees"),
    },
    {
      name: "cost-sharing",
      label: t("tab.costSharing"),
    },
    {
      name: "product-history",
      label: t("tab.productHistory"),
    },
  ]

  if (isFeatureZEVAppEnabled) {
    zevTabs.push({ name: "zev-app", label: t("tab.zevApp") })
    vewaTabs.push({ name: "zev-app", label: t("tab.zevApp") })
  }

  const renderTabs = () => {
    const correctTabList = isZev ? zevTabs : vewaTabs
    return correctTabList.map((item: ManagerSettingsTabsModels) => (
      <PrimaryTab label={item.label} key={item.name} data-testid={item.name} />
    ))
  }

  const primaryTabsLayout = () => {
    return (
      <PrimaryTabContainer value={selectedTab} onChange={(_, tab) => setSelectedTab(tab)} variant="scrollable">
        {renderTabs()}
      </PrimaryTabContainer>
    )
  }

  const renderTabPanels = () => {
    return (
      <>
        <TabPanel value={selectedTab} index={0}>
          <BillingSettingsTab contractId={contractId} />
        </TabPanel>

        <TabPanel value={selectedTab} index={1} sx={{ px: 2 }}>
          <FeesWrapper contractId={contractId} />
        </TabPanel>

        <TabPanel value={selectedTab} index={2}>
          {isZev ? <SettingsElectricityTab contractId={contractId} /> : <SettingsVewaCostSharesTab />}
        </TabPanel>

        <TabPanel value={selectedTab} index={3} sx={{ px: 3, pt: 2 }}>
          <ProductHistory contractId={contractId} />
        </TabPanel>

        <TabPanel value={selectedTab} index={4} sx={{ px: 6, pt: 6 }}>
          <ZevAppActivation />
          <ZevAppParticipants />
        </TabPanel>
      </>
    )
  }

  return (
    <Box>
      <ContractSelector />
      {primaryTabsLayout()}
      {renderTabPanels()}
    </Box>
  )
}
