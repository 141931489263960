import { v4 as uuidv4 } from "uuid"

type ConfigFile = {
  FEATURE_ZEV_APP: number | string[]
  API_BASE_URL: string
}

export const getUniqueId = () => uuidv4() + new Date().getTime()

export const getConfigFile = async (): Promise<ConfigFile | undefined> => {
  try {
    const response = await fetch("/config.json")
    const data = await response.json()
    return data
  } catch (error) {
    console.error("Error fetching config.json:", error)
    return undefined
  }
}
