"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = isNullOrUndefined;
function isNullOrUndefined(value) {
  return value === null || value === undefined;
}
module.exports = exports.default;
module.exports.default = exports.default;