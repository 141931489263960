"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = isOctal;
var _assertString = _interopRequireDefault(require("./util/assertString"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
var octal = /^(0o)?[0-7]+$/i;
function isOctal(str) {
  (0, _assertString.default)(str);
  return octal.test(str);
}
module.exports = exports.default;
module.exports.default = exports.default;