import { ContractAdminResponse } from "../../data/generated-sources/openapi"
import DateUtils from "./DateUtils"

export const getContractStartEndPeriod = ({
  startDate,
  endDate,
  formatType = "DD.MM.YYYY",
}: ContractAdminResponse & { formatType?: string }): string => {
  const formatedStartDate = DateUtils.getDeFormatDate(startDate, formatType)
  const formatedEndDate = endDate ? ` - ${DateUtils.getDeFormatDate(endDate, formatType)}` : ""
  return `${formatedStartDate}${formatedEndDate}`
}

export const checkZevAppEnabled = (flag: string[] | number | undefined, contractId: string) => {
  if (typeof flag === "number") {
    return flag === 1
  } else if (Array.isArray(flag)) {
    return flag.includes(contractId)
  }
  return false
}
